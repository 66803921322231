import { configureStore } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage'
import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
import thunk from 'redux-thunk'
import persistStore from 'redux-persist/es/persistStore'
import productsReducer from './views/products/marketplaceSlice'
import authReducer from './views/pages/login/loginSlice'
import profileReducer from './Profile/profileSlice'
import vendorsReducer from './views/vendor/vendorsSlice'
import energySourcesReducer from './views/energy-sources/energySourcesSlice'
import creditReducer from './views/credit-products/creditslice'

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['profile', 'auth'],
}

const reducers = combineReducers({
  marketPlace: productsReducer,
  auth: authReducer,
  profile: profileReducer,
  vendors: vendorsReducer,
  energySources: energySourcesReducer,
  credits: creditReducer,
})

const persistedReducer = persistReducer(persistConfig, reducers)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
})

export const persistor = persistStore(store)
