import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { BASE_URL } from 'src/environment'
import { httpClient } from 'src/httpClient'
import { getItemFromStorage } from '../Storage'

export const fetchProfile = createAsyncThunk('calculator/fetchProfile', ({ nextToken }) => {
  const { id } = getItemFromStorage({ name: 'auth' }) ?? {}
  return httpClient
    .get(`${BASE_URL}/profiles/${id}?token=${nextToken}`)
    .then((response) => response.data)
    .catch((error) => error)
})

const initialState = {
  profile: {
    data: {},
    error: {},
    status: 'idle',
  },
}

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchProfile.pending]: (state) => {
      state.profile = {
        status: 'loading',
        data: {},
        error: {},
      }
    },
    [fetchProfile.fulfilled]: (state, action) => {
      state.profile = {
        status: 'idle',
        data: action.payload,
        error: {},
      }
    },
    [fetchProfile.rejected]: (state, action) => {
      state.profile = {
        status: 'idle',
        data: {},
        error: action.payload,
      }
    },
  },
})

export const { logOut } = profileSlice.actions
export default profileSlice.reducer
