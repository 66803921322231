import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { BASE_URL } from 'src/environment'
import { httpClient } from 'src/httpClient'

export const getCredits = createAsyncThunk('credits/all', async () => {
  try {
    const response = await httpClient.get(`${BASE_URL}/admin/loan`)
    return response.data
  } catch (error) {
    console.log(error)
    return error
  }
})

export const getCreditDiscounts = createAsyncThunk('credits/discounts', async () => {
  try {
    const response = await httpClient.get(`${BASE_URL}/admin/discounts`)
    return response.data
  } catch (error) {
    console.log(error)
    return error
  }
})

export const updateCreditCalculator = createAsyncThunk('credits/update', async (data) => {
  try {
    const response = await httpClient.put(`${BASE_URL}/admin/loan/${data?.categoryId}`, data)
    return response.data
  } catch (error) {
    console.log(error)
    return error
  }
})

export const updateCreditDiscount = createAsyncThunk('credits/updateDiscount', async ({ data }) => {
  try {
    const response = await httpClient.put(
      `${BASE_URL}/admin/discounts/${data.categoryId}/enable`,
      data,
    )
    return response.data
  } catch (error) {
    console.log(error)
    return error
  }
})

export const disableDiscountForCategory = createAsyncThunk(
  'credits/disableDiscount',
  async ({ categoryId }) => {
    try {
      const response = await httpClient.put(`${BASE_URL}/admin/discounts/${categoryId}/disable`)
      return response.data
    } catch (error) {
      console.log(error)
      return error
    }
  },
)

const initialState = {
  data: [],
  discounts: [],
  updateInProgress: false,
  discountUpdateInProgress: false,
  updateSuccess: false,
}

const marketPlaceSlice = createSlice({
  name: 'marketPlace',
  initialState,
  reducers: {
    searchProduct: (state, action) => {
      state.searchTerm = action.payload
    },
  },
  extraReducers: {
    [getCredits.fulfilled]: (state, action) => {
      state.data = action.payload
      state.updateSuccess = false
    },
    [getCreditDiscounts.fulfilled]: (state, action) => {
      state.discounts = action.payload
    },
    [updateCreditCalculator.pending]: (state, action) => {
      state.updateInProgress = true
    },
    [updateCreditCalculator.fulfilled]: (state, action) => {
      state.updateInProgress = false
      state.updateSuccess = true
    },
    [updateCreditCalculator.rejected]: (state, action) => {
      state.updateInProgress = false
    },
    [updateCreditDiscount.pending]: (state, action) => {
      state.discountUpdateInProgress = true
    },
    [updateCreditDiscount.fulfilled]: (state, action) => {
      state.discountUpdateInProgress = false
    },
    [updateCreditDiscount.rejected]: (state, action) => {
      state.discountUpdateInProgress = false
    },
  },
})

export const { searchProduct } = marketPlaceSlice.actions
export default marketPlaceSlice.reducer
