import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { BASE_URL } from 'src/environment'
import { httpClient } from 'src/httpClient'

export const getProducts = createAsyncThunk('products/allProducts', (token) => {
  return httpClient
    .get(`${BASE_URL}/admin/products`, { params: token })
    .then((response) => response.data)
    .catch((error) => error)
})

export const getProduct = createAsyncThunk('products/product', (id) => {
  return httpClient
    .get(`${BASE_URL}/admin/products/product/${id}`)
    .then((response) => response.data)
    .catch((error) => error)
})

export const approveProduct = async (id) => {
  try {
    const response = await httpClient.put(`${BASE_URL}/admin/products/${id}/approve`)
    return response.data
  } catch (error) {
    return error
  }
}

export const rejectProduct = async (id, rejectionReason) => {
  try {
    const data = {
      rejectMessage: rejectionReason,
    }
    const response = await httpClient.put(`${BASE_URL}/admin/products/${id}/reject`, data)
    return response.data
  } catch (error) {
    console.log(error)
    return error
  }
}

export const searchProducts = createAsyncThunk('products/allProducts', (params) => {
  return httpClient
    .get(`${BASE_URL}/admin/products/search`, { params })
    .then((response) => response.data)
    .catch((error) => error)
})

const initialState = {
  products: [],
  searchTerm: '',
  nextToken: null,
}

const marketPlaceSlice = createSlice({
  name: 'marketPlace',
  initialState,
  reducers: {},
  extraReducers: {
    [getProducts.fulfilled]: (state, action) => {
      state.products = action.payload.data
      state.nextToken = action.payload.nextToken
    },
    [searchProducts.fulfilled]: (state, action) => {
      state.products = action.payload.data
      state.nextToken = action.payload.nextToken
    },
  },
})

export const {} = marketPlaceSlice.actions
export default marketPlaceSlice.reducer
