import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { BASE_URL } from 'src/environment'
import { httpClient } from 'src/httpClient'

export const getVendors = createAsyncThunk('vendors/allVendors', (token) => {
  return httpClient
    .get(`${BASE_URL}/admin/vendors/`, { params: token })
    .then((response) => response.data)
    .catch((error) => error)
})

export const searchVendors = createAsyncThunk('vendors/search', (params) => {
  return httpClient
    .get(`${BASE_URL}/admin/vendors/search/`, { params })
    .then((response) => response.data)
    .catch((error) => error)
})

export const getProductsByVendor = createAsyncThunk('vendors/products', async (id, nextToken) => {
  return httpClient
    .get(`${BASE_URL}/admin/vendors/${id}/products/`, { params: nextToken })
    .then((response) => response.data)
    .catch((error) => error)
})

const initialState = {
  vendors: [],
  productsByVendor: [],
  nextToken: null,
}

const vendorsSlice = createSlice({
  name: 'vendors',
  initialState,
  reducers: {},
  extraReducers: {
    [getVendors.fulfilled]: (state, action) => {
      state.vendors = action.payload.vendors
      state.nextToken = action.payload.nextToken
    },
    [getProductsByVendor.fulfilled]: (state, action) => {
      state.productsByVendor = action.payload.data
      state.nextToken = action.payload.nextToken
    },
    [searchVendors.fulfilled]: (state, action) => {
      state.vendors = action.payload.vendors
      state.nextToken = action.payload.nextToken
    },
  },
})

export const {} = vendorsSlice.actions
export default vendorsSlice.reducer
