import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { BASE_URL } from 'src/environment'
import { httpClient } from 'src/httpClient'

export const getEnergySources = createAsyncThunk('energySources/all', async () => {
  try {
    const response = await httpClient.get(`${BASE_URL}/energySources/`)
    return response.data
  } catch (error) {
    console.log(error)
    return error
  }
})

const initialState = {
  energySources: [],
}

const energySourcesSlice = createSlice({
  name: 'energySources',
  initialState,
  reducers: {},
  extraReducers: {
    [getEnergySources.fulfilled]: (state, action) => {
      state.energySources = action.payload
    },
  },
})

export const {} = energySourcesSlice.actions
export default energySourcesSlice.reducer
